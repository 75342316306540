<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%;"
  >
    <div class="pa-5 relative">
      <v-app-bar
        :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-4'"
        :dark="$vuetify.theme.dark"
        class="pt-1"
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">{{ $t("userBar.my_account") }}</h6>
          <slot name="userDrawerCloseButton"></slot>
        </div>
      </v-app-bar>
    </div>

    <div class="pa-5 mt-10">
      <base-card>
        <v-card-text>
          <div class="d-flex align-center mb-3">
            <v-avatar tile size="48" class="rounded" style="margin-inline-end: 12px;">
              <v-icon color="primary" class="primary lighten-5 ">
                mdi-account-outline
              </v-icon>
            </v-avatar>
            <div>
              <p class="card-title mb-0" style="font-size:1rem">{{ userInfo.email }}</p>
              <span class="text--disabled mt-0">
                {{ $t("userBar.balance") }}:
                <router-link :to="{ name: 'stats.detailed', params: { app: 'fd1' } }">
                  <strong>{{ userInfo.balance }}$</strong>
                </router-link>
              </span>
            </div>
          </div>

          <p class="text-body-2 text--secondary ma-0 ">
            {{ $t("userBar.available_for_withdrawal") }}: {{ userInfo.balance_withdrawal | toFixedCustom }}$
          </p>
          <p class="text-body-2 text--secondary ma-0 ">
            {{ $t("userBar.amount_in_hold") }}:
            <router-link :to="{ name: 'payouts' }">{{ userInfo.balance_in_hold | toFixedCustom }}$</router-link>
          </p>

          <v-divider class="py-0 mt-2"></v-divider>
        </v-card-text>

        <v-card-actions class="mt-0">
          <router-link :to="{ name: 'settings' }">
            <v-btn text color="blue" text-color="blue--text">
              {{ $t("userBar.edit_profile") }}
            </v-btn>
          </router-link>
        </v-card-actions>
      </base-card>

      <base-card>
        <v-card-text>
          <div class="d-flex align-center mb-3">
            <v-avatar class="green lighten-5 small  rounded mr-3" style="width: 24px; height: 24px; min-width: 24px;">
              <v-icon color="green" style="font-size: 16px;">mdi-bell-outline</v-icon>
            </v-avatar>

            <p class="card-title mb-0">{{ $t("userBar.latest_news") }}</p>
          </div>

          <div class="mb-3">
            <telegram-link />
          </div>

          <div v-for="newsItem in newsItems">
            <router-link :to="{ name: 'news' }" class="text--secondary font-weight-semi ma-0">{{
              newsItem.title
            }}</router-link>
            <p class="text--disabled text-caption">{{ newsItem.date }}</p>
          </div>

          <v-divider class="py-0 mt-6"></v-divider>
        </v-card-text>

        <v-card-actions>
          <v-btn text class="green--text text--accent-4 theme--light" :to="{ name: 'news' }">{{
            $t("userBar.more_news")
          }}</v-btn>
        </v-card-actions>
      </base-card>
    </div>
  </vue-perfect-scrollbar>
</template>

<script>
import TelegramLink from "../../views/components/TelegramLink";

export default {
  name: "UserDrawer",
  components: { TelegramLink },
  filters: {
    toFixedCustom(value) {
      return value.toFixed(2);
    }
  },
  data() {
    return {
      newsItems: [],
      items: [
        {
          letter: "A",
          title: "Angular",
          subTitle: "Frontend framework",
          progressbarText: "90",
          progressvalue: 90,
          progressColor: "danger"
        },
        {
          letter: "V",
          title: "Vue Js",
          subTitle: "Frontend framework",
          progressbarText: "30",
          progressvalue: 30,
          progressColor: "green"
        },
        {
          letter: "R",
          title: "React",
          subTitle: "Frontend framework",
          progressbarText: "50",
          progressvalue: 50,
          progressColor: "danger"
        },
        {
          letter: "W",
          title: "Wordpress Website",
          subTitle: "CMS",
          progressbarText: "30",
          progressvalue: 30,
          progressColor: "dark"
        }
      ]
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.auth.userData;
    }
  },
  mounted() {
    this.loadLatestNews();
  },
  methods: {
    loadLatestNews() {
      return this.axios.get("/news/latest").then(response => {
        this.newsItems = response.data.data;
      });
    }
  }
};
</script>

<style scoped></style>
